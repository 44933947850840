/** * 拍摄服务  */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/vrPanorams-shot-banner.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">为有VR需求企业提供 <br> 拍摄、制作、后期一体化服务</p>
            <p class="banner-text">给您极致完美的视觉体验 让品牌传播有声有色</p>
          </div>
      </v-img>
    </v-banner>
    
    <!-- 拍摄服务介绍 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>INDUSTRY SOLUTIONS</p>
        <div>
          <span>拍摄服务介绍</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered  color="#0084ff" class="g-tabs">
        <v-tab v-for="(v, k) in data.tabList" :key="k">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.tabList" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade-reverse" appear>
                <div class="right" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="tip" v-text="v.tip" />
                  <v-card-text class="text" v-for="(i, j) in v.text" :key="j" v-text="i" />
                </div>
              </transition>
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k"><img :src='v.img' /></div>
              </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>


    <!-- 我们的优势 -->
    <v-card class="warps warp3" :flat="true">
      <div class="public-title">
        <p>OUR ADVANTAGES</p>
        <div>
          <span>我们的优势</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="3" v-for="n in data.warp3" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <v-img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

     <!-- 配套服务 -->
    <!--<v-card class="warps warp4" :flat="true">-->
    <!--  <div class="public-title">-->
    <!--    <p>SUPPORTING SERVICES</p>-->
    <!--    <div>-->
    <!--      <span>配套服务</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <div class="con">-->
    <!--    <v-row>-->
    <!--      <v-col-->
    <!--        v-for="n in data.warp4"-->
    <!--        :key="n.id"-->
    <!--        class="d-flex child-flex"-->
    <!--        cols="3"-->
    <!--      >-->
    <!--        <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">-->
    <!--          <div>-->
    <!--            {{ n.tit }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </div>-->
    <!--</v-card>-->

        <!-- 一站式VR运营服务 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>VR MARKETING SERVICE</p>
        <div>
          <span>一站式VR运营服务</span>
          <span>打造集全集展示、运营转化、线上购物一体的全景互动运营系统</span>
        </div>
      </div>
      <v-container  class="d-flex justify-md-space-around mt-9">
        <div class="carousel-container">
          <div class="c-item-1 box-shadow" :class="currentHover === 1 ? 'c-item-hover':''" @mouseover="currentHover = 1;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-2 box-shadow" :class="currentHover === 2 ? 'c-item-hover':''" @mouseover="currentHover = 2;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-3 box-shadow" :class="currentHover === 3 ? 'c-item-hover':''" @mouseover="currentHover = 3;">
            <div class="c-item-cover"></div>
          </div>
          <div class="c-item-4 box-shadow" :class="currentHover === 4 ? 'c-item-hover':''" @mouseover="currentHover = 4;">
            <div class="c-item-cover"></div>
          </div>
        </div>
      </v-container>

    </v-card>


     <!-- 配套服务 -->
    <v-card class="warps warp5" :flat="true">
      <div class="public-title">
        <p>APPLICATION SCENARIO</p>
        <div>
          <span>合作流程</span>
        </div>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp5-img.png" />
      </div>
    </v-card>

    
  </v-app>
</template>

<script>
import textData from './textData.js'

export default {
  name: 'Shot',
  components: {
  },
  data() {
    return {
      data: textData.Shot,
      tab: null,
      currentHover: 1,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  
}

.warps {
  margin: 0 auto;
  text-align: center;
  width: 1200px !important;
}

.warp1 {
  .public-title {
    margin-top: 40px;
  }
  .g-tabs {
    // margin-top: 20px;
  }
  .box {
    width: 1000px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-left: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    .v-card__text {
      padding: 0;
    }
    .title {
      width: 300px;
      line-height: 31px;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
      color: #666;
    }
  }
}

.warp2 {
  margin: 0px auto 20px;
  .v-card__title{
    margin-top: 27px;
    padding: 6px 16px !important;
  }
  .v-card__text{
    line-height: 20px !important;
    padding: 6px 16px !important;
  }
  .v-card__title{
    width: 150px !important;
  }
  .public-title{
    margin-top: 22px;
  }
  .carousel-container {
    margin-top: 8px;
    width: 1060px;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    .c-item-hover{
      width: 560px;
    }
    .c-item-1{
      background-image: url(https://h5.ophyer.cn/official_website/other/vrPanorama-shot-warp2-img1.jpg);
      margin-right: 10px;
      background-size: cover;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-2{
      background-image: url(https://h5.ophyer.cn/official_website/other/vrPanorama-shot-warp2-img2.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-2:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-3{
      background-image: url(https://h5.ophyer.cn/official_website/other/vrPanorama-shot-warp2-img3.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-3:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-4{
      background-image: url(https://h5.ophyer.cn/official_website/other/vrPanorama-shot-warp2-img4.jpg);
      background-size: cover;
      background-position: 0;
      margin-right: 10px;
      overflow: hidden;
      position: relative;
      height: 400px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-4:not(.c-item-hover){
      width: 150px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-cover {
      width: 150px;
      height: 400px;
      border-radius: 14px;
      opacity: .6;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .c-item-1.c-item-cover,.c-item-2.c-item-cover,.c-item-3.c-item-cover,.c-item-4.c-item-cover{
      //background-position: 50%;
    }
  }
}

.warp3 {
  .public-title {
    margin-bottom: -10px;
  }
  .col-4{
    // height: 197px !important;
  }
  .img_title {
    width: 240px;
    height: 290px;
    margin: 0 auto;
    text-align: start;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #fff;
    .theme--light.v-image {
      margin: 0 auto;
      width:220px ;
      height: 142px;
    }
    h2 {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 10px;
      line-height: 22px;
    }
    &:hover {
      border: 1px solid #E5EBFF;
      border-radius: 8px;
      box-shadow: 0px 6px 11px 0px rgba(0, 60, 255, 0.1) !important;
    }
  }
}


.warp4 {
  // margin-top: -15px;
  margin-bottom: 30px;
  .con {
    // margin-top: 35px;
    .box-img {
      width: 240px;
      height: 160px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.warp5 {
  margin-bottom: 60px;
  .con {
    height: 200px;
  }
}


</style>
